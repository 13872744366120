@import "../../styles/partials/mixins";
@import "../../styles/partials/variables";

.mobileNav {
  @include display-flex(row, space-evenly, center);
  @include paddingsection;
  position: fixed;
  z-index: 9000;
  bottom: 0;
  width: 100%;
  height: 4rem;
  background-color: white;
  @include tablet {
    display: none;
  }
  &__link {
    background-color: lightgrey;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    @include display-flex(row, center, center);
    cursor: pointer;
  }
}

.mobileNav-dark {
  @include display-flex(row, space-evenly, center);
  @include paddingsection;
  position: fixed;
  z-index: 9000;
  bottom: 0;
  width: 100%;
  height: 4rem;
  background-color: white;
  @include tablet {
    display: none;
  }
  &__link {
    background-color: lightgrey;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    @include display-flex(row, center, center);
    cursor: pointer;
  }
}
