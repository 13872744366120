@import "../../styles/partials/mixins";
@import "../../styles/partials/variables";

.contact {
  @include wrapper;
  height: 75vh;
  @include paddingsection;
  @include display-flex(column, center, center);
  &-title {
    @include subtitle;
    @include h2Size;
    @include display-flex(row, center, center);
  }
  &-subtitle {
    margin: 1rem 0rem;
    font-size: 25px;
    a {
      color: $primary-accent !important;
    }
  }
  &__links {
    @include display-flex(row, center, center);
    margin-top: 1rem;
  }
  &__link {
    margin: 0rem 1rem;
    filter: invert(31%) sepia(98%) saturate(5780%) hue-rotate(230deg) brightness(94%) contrast(92%);
    &:hover {
      filter: invert(19%) sepia(52%) saturate(5077%) hue-rotate(246deg) brightness(107%) contrast(112%);
    }
  }
  &__img {
    width: 40px;
    transition: 0.3s;
    &:hover {
      transform: scale(1.1);
    }
  }
}
